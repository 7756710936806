<template>
  <Dialog v-show="active" class="backdrop-blur-sm">
    <template #header>
      <div class="mb-6">
        <div class="flex justify-between items-start">
          <h1 class="font-semibold text-[28px] text-brand-black leading-[110%]">Fill Application</h1>
          <img src="@/assets/icons/close-circle.svg" class="cursor-pointer" @click="$emit('close')" alt="" />
        </div>
        <p class="text-[16px] text-[#8F8F8F] leading-[160%]">Apply for {{ job?.role_title }}'s role</p>
      </div>
    </template>

    <form @submit.prevent="submitApply" enctype="multipart/form-data" id="applyForm" class="mb-10">
      <div class="w-full mb-3 items-center">
        <div class="mb-3">
          <label class="inline-block text-[14px] text-brand-black mb-2">Full Name <is-required /></label>
          <input
            type="text"
            required
            class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey2 rounded-xl bg-white placeholder:text-light-gray text-[14px]"
            placeholder="Type first name..."
            v-model="details.name"
            name="name"
          />
        </div>
      </div>

      <div class="w-full mb-3 items-center space-x-4">
        <div class="mb-3">
          <label class="inline-block text-[14px] text-brand-black mb-2">Email Address <is-required /></label>
          <input
            type="email"
            required
            class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey2 rounded-xl bg-white placeholder:text-light-gray text-[14px]"
            placeholder="Type an email address..."
            name="email"
            v-model="details.email"
          />
        </div>
      </div>

      <div class="w-full mb-3 items-center space-x-4">
        <div class="mb-3">
          <label class="inline-block text-[14px] text-brand-black mb-2">Phone Number <is-required /></label>
          <input
            type="text"
            required
            class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey2 rounded-xl bg-white placeholder:text-light-gray text-[14px]"
            placeholder="Type a phone number..."
            name="phone_no"
            v-model="details.phone_no"
          />
        </div>
      </div>

      <div class="w-full mb-3 items-center space-x-4">
        <div class="mb-3">
          <label for="resume-file" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Upload your resume file <is-required /></label>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            required
            class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey2 rounded-xl bg-white placeholder:text-light-gray text-[14px]"
            placeholder="Upload..."
            name="resume_file"
          />
        </div>
      </div>

      <div class="pt-4 space-x-2 md:space-x-4">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"> </VueRecaptcha>
        </div>
      </div>
    </form>

    <template #footer>
      <div class="">
        <div class="flex justify-end">
          <button
            class="text-white text-[15px] px-8 py-2 bg-brand-black rounded-lg mr-4 disabled:bg-brand-black/50"
            type="submit"
            form="applyForm"
            :disabled="!captcha"
          >
            Submit
          </button>
          <button
            type="button"
            class="text-brand-black text-[15px] px-8 py-2 rounded-lg border border-brand-black"
            @click="
              clearForm();
              $emit('close');
            "
          >
            Cancel
          </button>
        </div>
      </div>
    </template>
  </Dialog>

  <loader v-if="loading" />
</template>

<script setup>
import { ref, computed } from 'vue';
import Dialog from '@/components/partials/Dialog.vue';
import Loader from '@/components/LoadingOverlay.vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import IsRequired from '@/components/partials/IsRequired.vue';
import { errorMessage } from '@/utils/helpers';
import { VueRecaptcha } from 'vue-recaptcha';

const emit = defineEmits(['close']);

const props = defineProps({
  active: { type: Boolean, default: false },
  enums: { type: Array, default: [] },
  job: { type: Object },
});

const store = useStore();
const toast = useToast();
const loading = ref(false);
const details = ref({ name: '', email: '', phone_no: '', job_category: '' });
const captcha = ref('');
const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);

const handleError = () => {};

const handleSuccess = (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(response);
  }
  captcha.value = response;
};

const submitApply = async () => {
  const { name, email, phone_no } = details.value;
  if (!name || !email || !phone_no) {
    toast.error('Incomplete form data. Please try again', { timeout: 3000 });
    return;
  }

  loading.value = true;
  try {
    const formData = new FormData(document.querySelector('#applyForm'));
    formData.append('job_category', props.job?.job_title);
    await store.dispatch('global/applyWithoutSignIn', { id: props.job?.uuid, data: formData });

    toast.success('Application submitted successfully', {
      timeout: 3000,
    });

    emit('close');
  } catch (error) {
    captcha.value = '';
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
  } finally {
    captcha.value = '';
    loading.value = false;
  }
};

const clearForm = () => {
  details.value = { name: '', email: '', phone_no: '', job_category: '' };
};
</script>

<template>
  <div class="flex justify-center bg-[#FEFAF1]">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div>
        <div>
          <Search id="myHeader" />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div class="py-8">
        <a href="#" @click.prevent="$router.go(-1)" class="flex items-center">
          <img v-lazy="leftIcon" class="mr-2" alt="" />
          <span class="text-[#8B8B8B] text-[16px] leading-[26px]">Back to Jobs Page</span>
        </a>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[82vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div
          class="bg-cover bg-center h-[810px] bg-no-repeat rounded-lg"
          v-lazy:background-image="job?.employer?.banner_url ? job?.employer?.banner_url : bgImage"
        ></div>
        <br />

        <div class="p-4 md:p-8 bg-white rounded-xl">
          <ul class="inline-flex items-center border border-[#1C1C1E1F] rounded-lg p-1">
            <li class="mr-2">
              <a
                href="#"
                @click.prevent="tab = 0"
                aria-current="page"
                :class="{ 'text-white bg-brand-black font-medium': tab == 0 }"
                class="inline-block p-3 px-4 rounded-t-lg font-semibold text-center md:text-center text-[13px] md:text-[16px] rounded-lg"
                ><span class="hidden sm:inline-block">Job</span> Description</a
              >
            </li>
            <li class="mr-2">
              <a
                href="#"
                @click.prevent="tab = 1"
                :class="{ 'text-white bg-brand-black font-medium': tab == 1 }"
                class="inline-block p-3 px-4 rounded-t-lg font-semibold text-[13px] md:text-[16px] rounded-lg"
                >Renumeration</a
              >
            </li>
            <li class="hidden sm:inline-block" v-if="false">
              <a
                :class="{ 'text-white bg-brand-black font-medium': tab == 2 }"
                class="inline-block p-3 px-4 rounded-t-lg font-semibold text-[13px] md:text-[16px] rounded-lg"
                >Application</a
              >
            </li>
          </ul>

          <div class="mt-5" v-if="tab == 0">
            <div class="flex justify-between">
              <div>
                <h1 class="font-medium text-[#12080F] text-[32px] capitalize mb-2">
                  {{ job?.role_title }}
                </h1>
                <div class="flex items-center mb-2">
                  <img v-lazy="job?.employer?.logo_url" class="w-[24px] h-[24px] mr-2" alt="" />
                  <span class="text-[16px] text-[#1C1C1E] mr-2">{{ job?.employer?.name }}</span>
                  <span class="text-[#716B6F] text-[16px] capitalize" v-if="job?.work_type == 'remote'"> - {{ job?.work_type }}</span>
                  <template v-else>
                    <span class="text-[#716B6F] text-[16px]" v-if="hasLocation(job)">{{ job?.state?.name }}, {{ job?.state?.country_code }}</span>
                    <span class="text-[#716B6F] text-[16px]" v-else>Anywhere</span>
                  </template>
                </div>
                <div>
                  <span class="text-[#716B6F]/[0.72] text-[16px]">Posted on {{ moment(job?.created_at).format('D MMM, YYYY') }}</span>
                </div>
              </div>
              <div></div>
            </div>

            <div class="mt-6 mb-8 action">
              <button
                class="mr-3 bg-brand-black text-white px-[24px] py-[8px] rounded-[4px] border font-semibold text-[12px]"
                @click="showApply(job)"
              >
                Apply now
              </button>
              <button @click="tab = 1" class="'border-[#FF2449]/[0.2] px-[24px] py-[8px] rounded-[4px] border font-semibold text-[12px]">
                See Renumeration
              </button>
            </div>

            <div class="border border-[#1C1C1E]/[.12] rounded-md p-[16px] flex items-center justify-between flex-wrap mb-8">
              <div class="flex items-center mb-4">
                <img v-lazy="job?.postedBy?.avatar_url" class="w-[48px] h-[48px] rounded-full mr-4" alt="" />
                <div>
                  <h3 class="text-[#1C1C1E] text-[16px] font-semibold">
                    {{ job?.postedBy?.name }}
                  </h3>
                  <span class="text-[#1C1C1E]/[.72] text-[12px]">{{ job?.postedBy?.position }} {{ job?.employer?.name }}</span>
                </div>
              </div>

              <div>
                <button
                  class="mr-3 bg-brand-black text-white px-[24px] py-[12px] rounded-[4px] border font-semibold text-[12px]"
                  @click="showOverlay(job)"
                >
                  Send a message
                </button>
              </div>
            </div>

            <div v-html="job?.description" class="mt-2 mb-8" id="description"></div>

            <div class="bg-[#FFFAFB] border border-[#FFF5F7] px-5 py-6 rounded-xl mb-8" v-if="job?.must_have?.length">
              <h3 class="text-[20px] font-bold mb-5">Must-haves:</h3>
              <div>
                <ul class="list-disc pl-10">
                  <li class="mb-2 text-[#1B224B]/[0.6]" v-for="(item, index) in job?.must_have" :key="index">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="bg-[#FFFAFB] border border-[#FFF5F7] px-5 py-6 rounded-xl" v-if="job?.skills?.length">
              <h3 class="text-[20px] font-bold mb-5">Skills:</h3>
              <div>
                <ul class="list-disc pl-10">
                  <li class="mb-2 text-[#1B224B]/[0.6]" v-for="(skill, index) in job?.skills" :key="index">
                    {{ skill }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="tab == 1">
            <div class="my-6">
              <template v-if="hasSalary(job?.salary_min)">
                <small class="text-[#1B224B] text-[15px] mb-2 inline-block">Salary Renumeration</small>
                <h4 class="text-[#1B224B] font-bold text-[18px] lowercase">
                  {{ currencySymbol(job?.salary_currency) + formatNumber(job?.salary_min) }} –
                  {{ currencySymbol(job?.salary_currency) + formatNumber(job?.salary_max) }}
                  {{ job?.pay_period?.split('_').join(' ') }}
                </h4>
              </template>
              <template v-else> No salary information provided </template>
            </div>
          </div>
        </div>

        <div class="text-center mt-16">
          <router-link to="/all-jobs" class="px-8 py-4 bg-brand-black rounded-xl text-white">See more jobs</router-link>
        </div>
      </div>
    </div>
  </div>

  <job-apply-dialog :active="showApplyDialog" :enums="enums" :job="job" @close="showApplyDialog = false" />

  <discover-job />

  <Overlay v-if="overlay" :hide-overlay="hideOverlay" :job="job" />

  <loading v-if="isLoading" />
</template>

<script setup>
import bgImage from '@/assets/images/hiring.jpg';
import Type from '@/assets/icons/type.svg?inline';
import leftIcon from '@/assets/icons/chevron-left.svg';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import Search from '@/components/Search.vue';
import Loading from '@/components/LoadingOverlay.vue';
import moment from 'moment';
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import DiscoverJob from '@/components/jobs/DiscoverJob.vue';
import JobApplyDialog from '@/components/jobs/JobApply.vue';
import { currencySymbol, formatNumber, getDifferenceInDays } from '@/utils/helpers';
import { useMeta } from '@/utils/meta';
import { useToast } from 'vue-toastification';

const store = useStore();
const route = useRoute();
const enums = ref([]);
const job = ref();
const toast = useToast();
const isActive = ref(true);
const showApplyDialog = ref(false);
const isLoading = ref(true);
const overlay = ref(false);
const jobId = ref();
const tab = ref(0);

const isTrue = () => {
  isActive.value = !isActive.value;
};

const showApply = (job) => {
  if (job?.apply_without_signin) {
    showApplyDialog.value = true;
  } else {
    showOverlay(job);
  }
};

const showOverlay = (job) => {
  document.body.style.overflow = 'hidden';
  overlay.value = true;
};

const hideOverlay = () => {
  overlay.value = false;
  document.body.style.overflow = 'auto';
};

const hasSalary = (amount) => {
  const salary = amount ? parseInt(amount) : 0;
  if (salary < 1) return false;
  return true;
};

const hasLocation = (job) => {
  if (!job?.state?.name && !job?.state?.country) return false;
  return true;
};

const openJobs = computed(() => {
  return store.getters['getJobs'].filter((x) => x.status === 'open').slice(0, 4);
});

const candidate_jobs_url = computed(() => {
  return `${process.env.VUE_APP_CANDIDATE_URL}/jobs`;
});

onMounted(async () => {
  try {
    isLoading.value = true;
    job.value = await store.dispatch('getSingleJob', route.params.id);
    enums.value = await store.dispatch('global/getEnums');

    if (route.query.referralId) {
      await store.dispatch('global/sendReferralId', { referralId: route.query.referralId });
      toast.success('Application submitted successfully', {
        timeout: 5000,
      });
    }
  } catch (error) {
  } finally {
    isLoading.value = false;
  }
});

useMeta();
</script>

<style lang="scss" scoped>
.description::v-deep {
  line-height: 1.8rem;

  ul {
    list-style: disc;
    margin-left: 1rem;
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 360px) {
  .action {
    button {
      display: block;
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
